<template>
  <div class="app-container menus">
    <el-form
      :model="queryParams"
      ref="queryForm"
      v-hasPermi="['bank:stion:list']"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      style="width: 100%; height: 0.5%; text-align: left"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入标题"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="题库分类" prop="questionType">
        <el-select
          v-model="queryParams.questionType"
          placeholder="请选择分类"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in questionTypes"
            :key="dict.id"
            :label="dict.title"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="status">
        <el-select
          v-model="queryParams.optionsType"
          placeholder="请选择类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in bankType"
            :key="dict.id"
            :label="dict.value"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in bankStatus"
            :key="dict.id"
            :label="dict.value"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-hasPermi="['bank:stion:list']"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetQuery"
          v-hasPermi="['bank:stion:list']"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['bank:stion:add']"
          >新增</el-button
        >
      </el-col>
    </el-row>
    <el-table ref="tables" :data="list" v-loading="loading">
      <el-table-column label="编号" align="center" prop="id" width="100" />
      <el-table-column
        label="分类"
        align="center"
        prop="title"
        width="300"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="标题"
        align="center"
        prop="question"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="类型" align="center" prop="question">
        <template slot-scope="scope">
          <el-tag effect="plain" v-if="scope.row.options === 1">判断</el-tag>
          <el-tag effect="plain" v-if="scope.row.options === 2">单选</el-tag>
          <el-tag effect="plain" v-if="scope.row.options === 3">多选</el-tag>
          <el-tag effect="plain" v-if="scope.row.options === 4">填空</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="对/错"
        align="center"
        prop="rightOrWrong"
        width="150"
      />
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === '1'">正常</el-tag>
          <el-tag type="danger" v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['bank:stion:eqit']"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.parentId !== 0"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['bank:stion:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  queryQuestionBank,
  queryQuestionType,
  deleteQuestionBankById,
} from "@/api/exam/questionBank";
import { deleteQuestionTypeById } from "@/api/exam/questionType";
export default {
  name: "questionBank",
  data() {
    return {
      total: 0,
      //遮盖层
      loading: false,
      // 搜索
      showSearch: true,
      // 添加修改
      open: false,
      list: [],
      title: "",
      // 分类
      questionTypes: [],
      bankType: [
        { value: "判断", id: 1 },
        { value: "单选", id: 2 },
        { value: "多选", id: 3 },
        { value: "填写", id: 4 },
      ],
      bankStatus: [
        { value: "显示", id: "1" },
        { value: "隐藏", id: "0" },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        questionType: undefined,
        optionsType: undefined,
        status: undefined,
      },
    };
  },
  created() {
    this.getList();
    this.getQuestionType();
  },
  beforeRouteLeave(to, form, next) {
    next();
  },
  // 监听路由，每次进入页面调用方法
  activated() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      queryQuestionBank(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.pageBean.count;
        this.loading = false;
      });
    },
    getQuestionType() {
      queryQuestionType().then((res) => {
        this.questionTypes = res.data;
      });
    },
    handleAdd() {
      this.$router.push({ path: "/Home/addBank" });
    },
    handleUpdate(row) {
      const bankId = row.id;
      this.$router.push({ path: "/Home/addBank", query: { bankId: bankId } });
    },
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.title + '"的数据项？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const questionBankId = row.id;
        deleteQuestionBankById({ questionBankId }).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      });
    },
    cancel() {
      this.open = false;
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    resetQuery() {
      (this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        questionType: undefined,
        optionsType: undefined,
        status: undefined,
      }),
        this.getList();
      this.getQuestionType();
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
.mb8 {
  margin: 0 -5px 8px;
}
</style>