import request from '@/utils/request'

export function queryQuestionBank(data) {
    return request({
        url: 'questionBank/queryQuestionBank',
        method: 'get',
        params: data
    })
}

export function queryQuestionType(data) {
    return request({
        url: 'questionType/queryQuestionType',
        method: 'get',
        params: data
    })
}

export function queryQuestionBankByKey(questionBankById) {
    return request({
        url: 'questionBank/queryQuestionBankById',
        method: 'get',
        params: questionBankById
    })
}

export function addQuestionBank(questionbank) {
    return request({
        url: 'questionBank/addQuestionBank',
        method: 'post',
        data: questionbank
    })
}
export function updateQuestionBank(questionbank) {
    return request({
        url: 'questionBank/updateQuestionBank',
        method: 'put',
        data: questionbank
    })
}

export function deleteQuestionBankById(questionBankId) {
    return request({
        url: 'questionBank/deleteQuestionBankById',
        method: 'delete',
        data: questionBankId
    })
}